<template>
  <div class="m-3">
    <h2>Compliance Information</h2>
    <hr />
    <b-form-group
      id="compliance-group"
      label="Enter your home page compliance disclosure"
      label-for="textarea-compliance-1"
    >
    <b-form-textarea
      id="textarea-compliance-1"
      v-model="complianceText1"
      placeholder="Your app home page compliance paragraph"
      rows="3"
      max-rows="6"
    ></b-form-textarea>
    </b-form-group>


    <b-form-group
      id="compliance-group-2"
      label="Enter your email compliance disclosure"
      label-for="textarea-compliance-2"
    >
    <b-form-textarea
      id="textarea-compliance-2"
      v-model="emailComplianceText1"
      placeholder="Your email footer compliance paragraph"
      rows="3"
      max-rows="6"
    ></b-form-textarea>
    </b-form-group>
   

    <b-button
      variant="primary"
      block
      @click="setComplianceText"
      class="mb-3"
      :disabled="(complianceText1.length == 0 || emailComplianceText1.length == 0) || (complianceText1 == complianceText && emailComplianceText1 == emailComplianceText)" 
      >Update<b-spinner small v-if="updating"
    /></b-button>

    <h2>Any Additional Compliance URL</h2>
    <p>If you would like to link in the app directly to additional disclosure information on your own website, enter the disclosure website url here.  This could be a link such as "do not sell my personal information" which links to a page on your website.  Include the full link with the "https://" at the front. Also include the words for the link.</p>
    <hr />
    <b-form-group
      id="compliance-group"
      label="Enter the words that will be clicked by the user to visit the link."
      label-for="compliance-words"
    >
      <b-form-input
        id="compliance-words"
        placeholder="do not sell my personal information"
        v-model="complianceWords"
        trim
      ></b-form-input>
    </b-form-group>
    <b-form-group
      id="compliance-group"
      label="Enter compliance url"
      label-for="compliance-input"
    >
      <b-form-input
        id="compliance-input"
        placeholder="https://yourlink.com"
        v-model="complianceUrl"
        trim
      ></b-form-input>
    </b-form-group>
    <b-button
      variant="primary"
      block
      @click="setCompliance"
      class="mb-3"
      :disabled="complianceUrl.length == 0 || (complianceUrl == complianceUrl1 && complianceWords == complianceWords1)"
      >Update Additional Disclosure URL <b-spinner small v-if="settingCompliance"
    /></b-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      complianceText1: "",
      complianceText: "",
      complianceUrl: "",
      complianceUrl1: "",
      complianceWords:"",
      complianceWords1: "",
      emailComplianceText: "",
      emailComplianceText1: "",
      settingCompliance: false,
      updating: false,
      updatedComplianceText: {},
      updatedAdditionalCompliance: {}
    };
  },
  methods: {
    async setComplianceText() {
      this.updating = true;
      this.updatedComplianceText = {
        home_page_compliance_text: this.complianceText1,
        email_compliance_text: this.emailComplianceText1
      };

      try {
        await this.$http.put(
          this.$store.state.apiURL + "/compliance-text",
          this.updatedComplianceText,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.JWT,
            },
          }
        );

        this.showToast("Succesfully updated!", "Success!", "success");

        this.complianceText = this.complianceText1;
        this.emailComplianceText = this.emailComplianceText1;
      } catch (error) {
        this.handleError(error);
      }

      this.updating = false;
    },
    async setCompliance() {
      this.settingCompliance = true;
      this.updatedAdditionalCompliance = {
        url: this.complianceUrl,
        text: this.complianceWords
      }

      try {
        await this.$http.put(
          this.$store.state.apiURL + "/compliance-info",
          this.updatedAdditionalCompliance,
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.JWT,
            },
          }
        );

        this.complianceWords1 = this.complianceWords;
        this.complianceUrl1 = this.complianceUrl;
      } catch (error) {
        this.showToast("Not a valid URL", "Error!", "danger");
      }

      this.settingCompliance = false;
    },
  },
  async mounted() {
    try {
      let response = await this.$http.get(
        this.$store.state.apiURL + "/compliance-info",
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.JWT,
          },
        }
      );

      console.log("compliance info:  ", response.data)

      this.complianceUrl = response.data.url;
      this.complianceWords = response.data.url_text;
      this.complianceUrl1 = this.complianceUrl;
      this.complianceWords1 = this.complianceWords;

      /*
      // get auth status
      await this.$http.get(
        this.$store.state.apiURL + "/redtail-authorization",
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.JWT,
          },
        }
      );
      */
    } catch (error) {
      this.handleError(error);
    }

    try {
      let response = await this.$http.get(this.$store.state.apiURL + "/compliance-text", {
        headers: {
          Authorization: "Bearer " + this.$store.state.JWT,
        },
      });

      
      this.complianceText1 = response.data.home_page_compliance_text;
      this.emailComplianceText1 = response.data.email_compliance_text;
      this.complianceText = this.complianceText1;
      this.emailComplianceText = this.emailComplianceText1;
      

      console.log("GET compliance-text response succeeded in mounted:  ", response.data);
    } catch (error) {
      this.handleError(error);

      console.log("GET comliance-text response failed in mounted.");
    }
  },
};
</script>

<style lang="scss">
@import "../../scss/theme.scss"; 
</style>